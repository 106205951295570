// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOT_GUEST = '/guest';
const ROOT_LOGGED_IN = '/';

// ----------------------------------------------------------------------

export const PATH_GUEST = {
  root: ROOT_GUEST,
  login: path(ROOT_GUEST, '/login'),
  register: path(ROOT_GUEST, '/register'),
  verify: path(ROOT_GUEST, '/verify'),
  resetPassword: path(ROOT_GUEST, '/reset-password'),
  resendConfirmationEmail: path(ROOT_GUEST, '/resend-confirmation'),
  newPassword: path(ROOT_GUEST, '/new-password'),
};

export const PATH_LOGGED_IN = {
  root: ROOT_LOGGED_IN,
  dashboard: path(ROOT_LOGGED_IN, 'dashboard'),
  mySymptoms: path(ROOT_LOGGED_IN, 'mySymptoms'),
  myTrackings: path(ROOT_LOGGED_IN, 'myTrackings'),
  myCoach: path(ROOT_LOGGED_IN, 'myCoach'),
  mySessions: path(ROOT_LOGGED_IN, 'mySessions'),
  myProfile: path(ROOT_LOGGED_IN, 'myProfile'),

  calendar: path(ROOT_LOGGED_IN, 'calendar'),
  kanban: path(ROOT_LOGGED_IN, 'kanban'),
  permissionDenied: path(ROOT_LOGGED_IN, 'permission-denied'),
  user: {
    account: path(ROOT_LOGGED_IN, 'user/account'),
    edit: (name: string) => path(ROOT_LOGGED_IN, `user/${name}/edit`),
  },
  chat: {
    root: path(ROOT_LOGGED_IN, 'chat'),
    new: path(ROOT_LOGGED_IN, 'chat/new'),
    view: (name: string) => path(ROOT_LOGGED_IN, `chat/${name}`),
  },
};
